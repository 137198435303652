import React from 'react';

import classNames from 'classnames';
import capitalize from 'lodash/capitalize';

import DocumentOutline from '@travauxlib/shared/src/components/DesignSystem/assets/DocumentOutline.svg?react';
import Download from '@travauxlib/shared/src/components/DesignSystem/assets/Download.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { ColoredCircle } from '@travauxlib/shared/src/components/DesignSystem/components/ColoredCircle';
import { PvStatusTag } from '@travauxlib/shared/src/features/Chantiers/components/Pv/PvStatusTag';
import { PvType, ChantierPvWithDocuments } from '@travauxlib/shared/src/features/Chantiers/types';
import { PvDocumentName } from '@travauxlib/shared/src/features/Chantiers/utils/PvDocumentName';

import { PvActions } from './PvActions';
import { PvInfoText } from './PvInfoText';
import { useOpenUploadDocumentModal } from './UploadDocumentModal';

type Props = {
  pv?: ChantierPvWithDocuments;
  chantierUuid: string;
  pvType: PvType;
  disabledReason?: string;
};

export const PvBloc: React.FC<Props> = ({ pv, chantierUuid, pvType, disabledReason }) => {
  const openUploadDocumentModal = useOpenUploadDocumentModal();
  const readOnly = !!pv?.isValidated;

  return (
    <div
      className={classNames('flex items-center', disabledReason && 'bg-neutral-100')}
      aria-labelledby={`${pvType}-card`}
    >
      <ColoredCircle size="sm" className="m-xs" variant={disabledReason ? 'light' : 'beige'}>
        <DocumentOutline />
      </ColoredCircle>
      <div className="flex-grow py-xxs">
        <div
          className={classNames(
            'text-neutral-800 text-ds-b2',
            disabledReason && '!text-neutral-300',
          )}
          id={`${pvType}-card`}
        >
          {capitalize(PvDocumentName[pvType])}
        </div>
        <PvInfoText disabled={!!disabledReason} documents={pv?.documents} />
      </div>
      {pv?.documents?.length ? (
        <div className="flex items-center">
          <PvStatusTag pv={pv} />
          <PvActions pv={pv} readOnly={readOnly} />
        </div>
      ) : (
        <Button
          size="sm"
          onClick={() =>
            openUploadDocumentModal({
              chantierUuid,
              pvType,
              title: `Ajouter le procès-verbal de ${PvDocumentName[pvType]}`,
            })
          }
          leftIcon={<Download />}
          variant={disabledReason ? 'primary' : 'tertiary'}
          disabled={!!disabledReason}
          disabledMessageTooltip={disabledReason}
          widthTooltip="19.5rem"
          positionTooltip="top-end"
        >
          <span className="hidden sm-desktop:block"> Ajouter le document</span>
        </Button>
      )}
    </div>
  );
};
