import React from 'react';

import classNames from 'classnames';

import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { useOpenThresholdModal } from '@travauxlib/shared/src/features/SuiviChantier/hooks/useOpenThresholdModal';

import { ChantierPro } from '../api/useDealChantier';

type Props = {
  chantier: ChantierPro;
};

export const FrozenAmountCard: React.FC<Props> = ({
  chantier: { leveeReservesThreshold, receptionChantierThreshold, montantGele, currentThreshold },
}) => {
  const openThresholdModal = useOpenThresholdModal();

  return (
    <Card
      className="!shadow-ds-xl !rounded-md border border-neutral-300"
      bodyClassNames="!p-lg"
      aria-labelledby="frozenAmount"
    >
      <div className="flex items-center justify-between gap-xs mb-lg">
        <div className="text-ds-h5 font-bold" id="frozenAmount">
          Montant en attente
        </div>
        <Link
          variant="secondary"
          size="lg"
          onClick={() =>
            openThresholdModal({
              leveeReservesThreshold: leveeReservesThreshold,
              receptionChantierThreshold: receptionChantierThreshold,
              openCrisp: () => window.$crisp.push(['do', 'chat:open']),
              isForPro: true,
            })
          }
          inline
        >
          En savoir plus
        </Link>
      </div>
      <div className="flex flex-col sm-desktop:flex-row gap-sm">
        <div className="flex flex-col text-ds-b1 flex-1">
          Solde
          <EURCurrency
            className={classNames(
              'text-[1.75rem] leading-[2.625rem] font-bold',
              montantGele > 0 ? 'text-warning' : 'text-neutral-800',
            )}
            amount={montantGele}
          />
          Plafond {currentThreshold}%
        </div>
      </div>
    </Card>
  );
};
